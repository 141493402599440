
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as invalid_45password_45reset_45tokenHvgzP2f6lNMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/invalid-password-reset-token.vue?macro=true";
import { default as _91accountSlug_93KvDaMkjhWUMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/login/[accountSlug].vue?macro=true";
import { default as indexJC5ST6ghsPMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/login/index.vue?macro=true";
import { default as logoutT4DDcSo0MbMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/logout.vue?macro=true";
import { default as request_45password_45reset_45confirmationMtOP7CIVWwMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset-confirmation.vue?macro=true";
import { default as request_45password_45reset4DxUe558E2Meta } from "/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset.vue?macro=true";
import { default as reset_45password_45confirmationRG2RExxdlkMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/reset-password-confirmation.vue?macro=true";
import { default as reset_45passwordBI6yvYngksMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/reset-password.vue?macro=true";
import { default as settingsdy2kRmbIVeMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/settings.vue?macro=true";
import { default as _91hash_93wINMVrUdXOMeta } from "/home/runner/work/dashboard/dashboard/pages/auth/verify-email/[userId]/[hash].vue?macro=true";
import { default as _91categoryId_93MUYEY2IL2kMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/[categoryId].vue?macro=true";
import { default as _91salesChannelId_93jcdNfLhyHQMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/create/[salesChannelId].vue?macro=true";
import { default as indexLbaC5b4OauMeta } from "/home/runner/work/dashboard/dashboard/pages/categories/index.vue?macro=true";
import { default as categoriesV6Lx1InbDKMeta } from "/home/runner/work/dashboard/dashboard/pages/categories.vue?macro=true";
import { default as codesi6k5OdZVGpMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/codes.vue?macro=true";
import { default as indexwCJb95loJyMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/index.vue?macro=true";
import { default as createofupGenh4oMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/create.vue?macro=true";
import { default as index1iX6RBJy9wMeta } from "/home/runner/work/dashboard/dashboard/pages/discounts/index.vue?macro=true";
import { default as _91enhancementId_93N69xBqolMFMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/[enhancementId].vue?macro=true";
import { default as createDLGLkX4NdPMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/create.vue?macro=true";
import { default as indexyqkdGS8dntMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements/index.vue?macro=true";
import { default as enhancements3yKSJ3BV9IMeta } from "/home/runner/work/dashboard/dashboard/pages/enhancements.vue?macro=true";
import { default as indexMm2ZM7LfDiMeta } from "/home/runner/work/dashboard/dashboard/pages/index.vue?macro=true";
import { default as _91invitationId_93PhL1nSwbDuMeta } from "/home/runner/work/dashboard/dashboard/pages/invitation/[invitationId].vue?macro=true";
import { default as invalidcxFIIDEfnsMeta } from "/home/runner/work/dashboard/dashboard/pages/invitation/invalid.vue?macro=true";
import { default as _91orderId_93Dd4FVazpyRMeta } from "/home/runner/work/dashboard/dashboard/pages/orders/[orderId].vue?macro=true";
import { default as indexZTkQzWDGkIMeta } from "/home/runner/work/dashboard/dashboard/pages/orders/index.vue?macro=true";
import { default as orderspokBprXfafMeta } from "/home/runner/work/dashboard/dashboard/pages/orders.vue?macro=true";
import { default as indexXu9adEzwKOMeta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/index.vue?macro=true";
import { default as _91variantId_93SzwQXnDlQ4Meta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/[variantId].vue?macro=true";
import { default as createKYH9TJufenMeta } from "/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/create.vue?macro=true";
import { default as _91type_935SaHedTheOMeta } from "/home/runner/work/dashboard/dashboard/pages/products/create/[type].vue?macro=true";
import { default as createdPWMzbJzG6Meta } from "/home/runner/work/dashboard/dashboard/pages/products/create/variants/create.vue?macro=true";
import { default as indexX9E3n1vsapMeta } from "/home/runner/work/dashboard/dashboard/pages/products/index.vue?macro=true";
import { default as productsvUwbW9DgHqMeta } from "/home/runner/work/dashboard/dashboard/pages/products.vue?macro=true";
import { default as indexABfqqO6wyGMeta } from "/home/runner/work/dashboard/dashboard/pages/reports/index.vue?macro=true";
import { default as reportshCmlfPrGB1Meta } from "/home/runner/work/dashboard/dashboard/pages/reports.vue?macro=true";
import { default as billingMRFCTtWjZ3Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/billing.vue?macro=true";
import { default as _91hostname_93QOBGLnSTZ9Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains/[hostname].vue?macro=true";
import { default as indexvP7FMhrJgqMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains/index.vue?macro=true";
import { default as email_45domains6atHaIv86FMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/email-domains.vue?macro=true";
import { default as generalzRHj1DMZGUMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/general.vue?macro=true";
import { default as indexgLKkuUg2x3Meta } from "/home/runner/work/dashboard/dashboard/pages/settings/index.vue?macro=true";
import { default as paypalZZkuduttlvMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/paypal.vue?macro=true";
import { default as stripeJA7eJVskJMMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/stripe.vue?macro=true";
import { default as indexzXE2qsy69rMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/index.vue?macro=true";
import { default as _91redeemLocationId_93yKsWNCbCaPMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/[redeemLocationId].vue?macro=true";
import { default as createrEW6HHLqdKMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/create.vue?macro=true";
import { default as indexkwe8VZKJSxMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/index.vue?macro=true";
import { default as redeem_45locationsawD0PJp3TmMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations.vue?macro=true";
import { default as _91userId_93xvSzuW5bUoMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/[userId].vue?macro=true";
import { default as emailBXfCz7IIqwMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/create/email.vue?macro=true";
import { default as usernameAFdgxoXa5kMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/create/username.vue?macro=true";
import { default as indexgwtn5cfBPIMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/index.vue?macro=true";
import { default as _91invitationId_93joJAxk8iObMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/[invitationId].vue?macro=true";
import { default as indexGaM0PSVv1bMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/index.vue?macro=true";
import { default as userscBFy1d0MemMeta } from "/home/runner/work/dashboard/dashboard/pages/settings/users.vue?macro=true";
import { default as settingslZuwzuWYJ0Meta } from "/home/runner/work/dashboard/dashboard/pages/settings.vue?macro=true";
import { default as _91page_93Ai3wtoRJpQMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/[page].vue?macro=true";
import { default as indexvN9O1GV0KqMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/index.vue?macro=true";
import { default as faq5kWEiV8EKiMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/faq.vue?macro=true";
import { default as indexN3mLogBYmaMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/index.vue?macro=true";
import { default as privacyf9pEyNN8Z1Meta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/privacy.vue?macro=true";
import { default as termsFdakOLnHINMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/terms.vue?macro=true";
import { default as _91page_938roXampeqIMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/[page].vue?macro=true";
import { default as indexCo4HWlaguYMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/index.vue?macro=true";
import { default as faqHWj8UmEqT3Meta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/faq.vue?macro=true";
import { default as indexTEH9zmYUhtMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/index.vue?macro=true";
import { default as privacyByP9mSA6KXMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/privacy.vue?macro=true";
import { default as termsaUb4XBqvwiMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/terms.vue?macro=true";
import { default as indexgwyxZtsxIHMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/index.vue?macro=true";
import { default as interstitialmvRZM7J5BAMeta } from "/home/runner/work/dashboard/dashboard/pages/stores/interstitial.vue?macro=true";
import { default as storesmaIhalQqooMeta } from "/home/runner/work/dashboard/dashboard/pages/stores.vue?macro=true";
import { default as settingsOaNJChXSSfMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/auth/settings.vue?macro=true";
import { default as indexr0ZYRS1BiLMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/index.vue?macro=true";
import { default as _91reference_939tx0bUJDl4Meta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/[reference].vue?macro=true";
import { default as confirmationugEPFNdj5fMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/confirmation.vue?macro=true";
import { default as indexLSyr03I8CQMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/index.vue?macro=true";
import { default as _91redeemableId_93Wa2AUKs3QtMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue?macro=true";
import { default as indexCKqzUDBLwKMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/index.vue?macro=true";
import { default as completepp7OiHxH5yMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/complete.vue?macro=true";
import { default as deliveryqTvitrxriUMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/delivery.vue?macro=true";
import { default as indexr4p2ow5vDvMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/index.vue?macro=true";
import { default as enhancementsUMGlA9BJlSMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/enhancements.vue?macro=true";
import { default as indexjB4rwllUV4Meta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/index.vue?macro=true";
import { default as monetaryqd8TPvIz5qMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/monetary.vue?macro=true";
import { default as personalisationfQhUu3a4cFMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/personalisation.vue?macro=true";
import { default as variantjwZxGIacR1Meta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/variant.vue?macro=true";
import { default as summary0XeSYsR1iHMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/summary.vue?macro=true";
import { default as index6lSwT1kNQAMeta } from "/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/index.vue?macro=true";
export default [
  {
    name: "auth-invalid-password-reset-token",
    path: "/auth/invalid-password-reset-token",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/invalid-password-reset-token.vue")
  },
  {
    name: "auth-login-accountSlug",
    path: "/auth/login/:accountSlug()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/login/[accountSlug].vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/login/index.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/logout.vue")
  },
  {
    name: "auth-request-password-reset-confirmation",
    path: "/auth/request-password-reset-confirmation",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset-confirmation.vue")
  },
  {
    name: "auth-request-password-reset",
    path: "/auth/request-password-reset",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/request-password-reset.vue")
  },
  {
    name: "auth-reset-password-confirmation",
    path: "/auth/reset-password-confirmation",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/reset-password-confirmation.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/reset-password.vue")
  },
  {
    name: "auth-settings",
    path: "/auth/settings",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/settings.vue")
  },
  {
    name: "auth-verify-email-userId-hash",
    path: "/auth/verify-email/:userId()/:hash()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/auth/verify-email/[userId]/[hash].vue")
  },
  {
    name: categoriesV6Lx1InbDKMeta?.name,
    path: "/categories",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryId",
    path: ":categoryId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/[categoryId].vue")
  },
  {
    name: "categories-create-salesChannelId",
    path: "create/:salesChannelId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/create/[salesChannelId].vue")
  },
  {
    name: "categories",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/categories/index.vue")
  }
]
  },
  {
    name: "discounts-discountId-codes",
    path: "/discounts/:discountId()/codes",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/codes.vue")
  },
  {
    name: "discounts-discountId",
    path: "/discounts/:discountId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/[discountId]/index.vue")
  },
  {
    name: "discounts-create",
    path: "/discounts/create",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/create.vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/discounts/index.vue")
  },
  {
    name: enhancements3yKSJ3BV9IMeta?.name,
    path: "/enhancements",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements.vue"),
    children: [
  {
    name: "enhancements-enhancementId",
    path: ":enhancementId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/[enhancementId].vue")
  },
  {
    name: "enhancements-create",
    path: "create",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/create.vue")
  },
  {
    name: "enhancements",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/enhancements/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/index.vue")
  },
  {
    name: "invitation-invitationId",
    path: "/invitation/:invitationId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/invitation/[invitationId].vue")
  },
  {
    name: "invitation-invalid",
    path: "/invitation/invalid",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/invitation/invalid.vue")
  },
  {
    name: orderspokBprXfafMeta?.name,
    path: "/orders",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders.vue"),
    children: [
  {
    name: "orders-orderId",
    path: ":orderId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders/[orderId].vue")
  },
  {
    name: "orders",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/orders/index.vue")
  }
]
  },
  {
    name: productsvUwbW9DgHqMeta?.name,
    path: "/products",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products.vue"),
    children: [
  {
    name: "products-productId",
    path: ":productId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/index.vue")
  },
  {
    name: "products-productId-variants-variantId",
    path: ":productId()/variants/:variantId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/[variantId].vue")
  },
  {
    name: "products-productId-variants-create",
    path: ":productId()/variants/create",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/[productId]/variants/create.vue")
  },
  {
    name: "products-create-type",
    path: "create/:type()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/create/[type].vue")
  },
  {
    name: "products-create-variants-create",
    path: "create/variants/create",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/create/variants/create.vue")
  },
  {
    name: "products",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/products/index.vue")
  }
]
  },
  {
    name: reportshCmlfPrGB1Meta?.name,
    path: "/reports",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/reports.vue"),
    children: [
  {
    name: "reports",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/reports/index.vue")
  }
]
  },
  {
    name: settingslZuwzuWYJ0Meta?.name,
    path: "/settings",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings.vue"),
    children: [
  {
    name: "settings-billing",
    path: "billing",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/billing.vue")
  },
  {
    name: email_45domains6atHaIv86FMeta?.name,
    path: "email-domains",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains.vue"),
    children: [
  {
    name: "settings-email-domains-hostname",
    path: ":hostname()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains/[hostname].vue")
  },
  {
    name: "settings-email-domains",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/email-domains/index.vue")
  }
]
  },
  {
    name: "settings-general",
    path: "general",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/general.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/index.vue")
  },
  {
    name: "settings-payment-gateways-connect-paypal",
    path: "payment-gateways/connect/paypal",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/paypal.vue")
  },
  {
    name: "settings-payment-gateways-connect-stripe",
    path: "payment-gateways/connect/stripe",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/connect/stripe.vue")
  },
  {
    name: "settings-payment-gateways",
    path: "payment-gateways",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/payment-gateways/index.vue")
  },
  {
    name: redeem_45locationsawD0PJp3TmMeta?.name,
    path: "redeem-locations",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations.vue"),
    children: [
  {
    name: "settings-redeem-locations-redeemLocationId",
    path: ":redeemLocationId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/[redeemLocationId].vue")
  },
  {
    name: "settings-redeem-locations-create",
    path: "create",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/create.vue")
  },
  {
    name: "settings-redeem-locations",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/redeem-locations/index.vue")
  }
]
  },
  {
    name: userscBFy1d0MemMeta?.name,
    path: "users",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users.vue"),
    children: [
  {
    name: "settings-users-userId",
    path: ":userId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/[userId].vue")
  },
  {
    name: "settings-users-create-email",
    path: "create/email",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/create/email.vue")
  },
  {
    name: "settings-users-create-username",
    path: "create/username",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/create/username.vue")
  },
  {
    name: "settings-users",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/index.vue")
  },
  {
    name: "settings-users-invitations-invitationId",
    path: "invitations/:invitationId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/[invitationId].vue")
  },
  {
    name: "settings-users-invitations",
    path: "invitations",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/settings/users/invitations/index.vue")
  }
]
  }
]
  },
  {
    name: storesmaIhalQqooMeta?.name,
    path: "/stores",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores.vue"),
    children: [
  {
    name: "stores-salesChannelId-emails-page",
    path: ":salesChannelId()/emails/:page()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/[page].vue")
  },
  {
    name: "stores-salesChannelId-emails",
    path: ":salesChannelId()/emails",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/emails/index.vue")
  },
  {
    name: "stores-salesChannelId-faq",
    path: ":salesChannelId()/faq",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/faq.vue")
  },
  {
    name: "stores-salesChannelId",
    path: ":salesChannelId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/index.vue")
  },
  {
    name: "stores-salesChannelId-privacy",
    path: ":salesChannelId()/privacy",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/privacy.vue")
  },
  {
    name: "stores-salesChannelId-terms",
    path: ":salesChannelId()/terms",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/[salesChannelId]/terms.vue")
  },
  {
    name: "stores-create-type-emails-page",
    path: "create/:type()/emails/:page()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/[page].vue")
  },
  {
    name: "stores-create-type-emails",
    path: "create/:type()/emails",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/emails/index.vue")
  },
  {
    name: "stores-create-type-faq",
    path: "create/:type()/faq",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/faq.vue")
  },
  {
    name: "stores-create-type",
    path: "create/:type()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/index.vue")
  },
  {
    name: "stores-create-type-privacy",
    path: "create/:type()/privacy",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/privacy.vue")
  },
  {
    name: "stores-create-type-terms",
    path: "create/:type()/terms",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/create/[type]/terms.vue")
  },
  {
    name: "stores",
    path: "",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/index.vue")
  },
  {
    name: "stores-interstitial",
    path: "interstitial",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/stores/interstitial.vue")
  }
]
  },
  {
    name: "terminal-auth-settings",
    path: "/terminal/auth/settings",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/auth/settings.vue")
  },
  {
    name: "terminal",
    path: "/terminal",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/index.vue")
  },
  {
    name: "terminal-redeem-reference",
    path: "/terminal/redeem/:reference()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/[reference].vue")
  },
  {
    name: "terminal-redeem-confirmation",
    path: "/terminal/redeem/confirmation",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/confirmation.vue")
  },
  {
    name: "terminal-redeem",
    path: "/terminal/redeem",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/index.vue")
  },
  {
    name: "terminal-redeem-voucher-reference-redeemableId",
    path: "/terminal/redeem/voucher/:reference()/:redeemableId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue")
  },
  {
    name: "terminal-redeem-voucher-reference",
    path: "/terminal/redeem/voucher/:reference()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/redeem/voucher/[reference]/index.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-complete",
    path: "/terminal/sell/:type()/:salesChannelId()/complete",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/complete.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-delivery",
    path: "/terminal/sell/:type()/:salesChannelId()/delivery",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/delivery.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId",
    path: "/terminal/sell/:type()/:salesChannelId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/index.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-enhancements",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/enhancements",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/enhancements.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/index.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-monetary",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/monetary",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/monetary.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-personalisation",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/personalisation",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/personalisation.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-variant",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/variant",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/variant.vue")
  },
  {
    name: "terminal-sell-type-salesChannelId-summary",
    path: "/terminal/sell/:type()/:salesChannelId()/summary",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/[salesChannelId]/summary.vue")
  },
  {
    name: "terminal-sell-type",
    path: "/terminal/sell/:type()",
    component: () => import("/home/runner/work/dashboard/dashboard/pages/terminal/sell/[type]/index.vue")
  }
]